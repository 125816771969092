import { Spinner } from '@chakra-ui/react';
import { Button } from '@memed/epiderme';
import { useEffect } from 'react';

import { Container } from '../../components/Container';
import { Header } from '../../components/Header';
import { Message } from '../../components/Message';
import { Title } from '../../components/Title';
import { CREDENTIALS } from '../../constants/credentials';
import { useCredentials } from '../../contexts/credentials';
import { CardsList } from './CardsList';

export const Credentials = () => {
  const context = useCredentials();
  const { dispatch, state } = context;

  useEffect(() => {
    dispatch({
      type: 'credentials/getAll',
    });
  }, [dispatch]);

  const createNewkeyPair = () => {
    dispatch({ type: 'credentials/add' });
  };

  return (
    <>
      <Header title="credenciais">
        <Button
          leftIcon="plus"
          size="sm"
          onClick={createNewkeyPair}
          disabled={state.loading}
        >
          Novas chaves
        </Button>
      </Header>

      <Container>
        {!context.state.generatedKeys.length && !context.state.loading && (
          <Message message={CREDENTIALS.emptyList} />
        )}

        {context.state.loading ? <Spinner /> : null}

        <CardsList />
      </Container>
    </>
  );
};
