import { useEffect, useState } from 'react';

export function useCopy() {
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const copyText = async (textCopy: string) => {
    if ('clipboard' in navigator) {
      return navigator.clipboard.writeText(textCopy);
    }
    return document.execCommand('copy', true, textCopy);
  };

  const handleCopy = (textCopy: string) => {
    copyText(textCopy)
      .then(() => {
        setIsCopied(true);

        setTimeout(() => {
          setIsCopied(false);
        }, 2000);
      })
      .catch(() => {
        setIsCopied(false);
      });
  };

  return { handleCopy, isCopied };
}
