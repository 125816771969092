import { Button, Flex, Heading } from '@memed/epiderme';
import { useNavigate } from 'react-router-dom';

export const Page404 = () => {
  const navigate = useNavigate();

  return (
    <Flex
      height="100vh"
      direction="column"
      gap="8"
      padding="12"
      alignItems="center"
      justifyContent="center"
      bgColor="blue.50"
    >
      <Heading fontWeight="medium" color="primary.600" textAlign="center">
        Página não encontrada
      </Heading>

      <Button leftIcon="arrow-left" onClick={() => navigate('/')}>
        Voltar para home
      </Button>
    </Flex>
  );
};
