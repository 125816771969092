import { ReactNode } from 'react';

import { Compose } from './Compose';
import { AuthProvider } from './auth';
import { CredentialsProvider } from './credentials';

interface ContextType {
  children: ReactNode;
}

const contexts = [CredentialsProvider, AuthProvider];

export const ContextsProviders = ({ children }: ContextType) => (
  <Compose components={contexts}>{children}</Compose>
);
