import { Heading } from '@memed/epiderme';
import { HtmlHTMLAttributes } from 'react';

import { useResize } from '../../hooks/useResize';

export const Title = ({ children }: HtmlHTMLAttributes<HTMLDivElement>) => {
  const { isTablet } = useResize();

  const titleType = isTablet ? 'subtitle' : 'title-sm';

  return (
    <Heading type={titleType} fontWeight="medium" textTransform="capitalize">
      {children}
    </Heading>
  );
};
