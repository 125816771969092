import { Text } from '@memed/epiderme';

interface MessageProps {
  message: string;
}

export const Message = ({ message }: MessageProps) => (
  <Text fontStyle="italic" color="neutral.600">
    {message}
  </Text>
);
