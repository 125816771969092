import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { messages } from '../constants/messages';
import { formatterKeyName } from '../utils/formatterKeyName';
import { recoverSessionStorage } from '../utils/recoverSessionStorage';

interface TypeObject {
  [key: string]: string | number;
}

const AUTH_AUTHORITY_URL = process.env.REACT_APP_AUTH_AUTHORITY_URL;
const AUTH_CLIENT_ID = process.env.REACT_APP_AUTH_CLIENT_ID;

const session = recoverSessionStorage(
  `oidc.user:${AUTH_AUTHORITY_URL}:${AUTH_CLIENT_ID}`,
);

export const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL as string,
});

api.interceptors.request.use(
  (request: AxiosRequestConfig): AxiosRequestConfig => {
    if (session?.access_token) {
      request.headers = {
        'x-token': session.access_token,
      };
    }

    if (!request.data) {
      return request;
    }

    if (
      request.data instanceof URLSearchParams
      || request.data instanceof FormData
    ) {
      return request;
    }

    request.data = formatterKeyName('snakeCase', request?.data as TypeObject);

    return request;
  },
);

api.interceptors.response.use(
  (response: AxiosResponse): AxiosResponse => {
    const data = formatterKeyName(
      'camelCase',
      response.data as TypeObject | Array<TypeObject>,
    );

    response.data = data;

    return response;
  },
  (errorResponse: AxiosError) => {
    const code: number | undefined = errorResponse.response?.status;
    const message = code ? messages[`code-${code}`] : null;

    if (code) {
      toast(message as string, {
        type: 'error',
      });
    }
  },
);
