import { KcContextBase } from 'keycloakify';

declare global {
  interface Window {
    kcContext: KcContextBase;
  }
}

const context: KcContextBase | null = window.kcContext;

export type LoginContext = {} & KcContextBase.Login;

export interface AuthServerContextProps {
  context: KcContextBase | null;
  isAuthServerRendering: () => boolean;
  login: LoginContext;
}

export function isAuthServerRendering(): boolean {
  return !!context;
}

export function getAuthServerContext(): AuthServerContextProps {
  return {
    context,
    isAuthServerRendering,
    login: context as LoginContext,
  };
}
