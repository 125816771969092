import theme from '@memed/epiderme';
import { useEffect, useState } from 'react';

const remToPixel = (rem: string, base = 16) => {
  const stringToNumber = parseInt(rem.replace('rem', ''), 10);

  return stringToNumber * base;
};

export function useResize() {
  const { breakpoints } = theme;

  const [width, setWidth] = useState<number>(0);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const currentBreakpoint = {
    isMobile: width > 0 && width <= remToPixel(breakpoints.sm),
    isTablet:
      width > remToPixel(breakpoints.sm) && width <= remToPixel(breakpoints.md),
    isLaptop:
      width > remToPixel(breakpoints.md) && width <= remToPixel(breakpoints.lg),
    isDesktop:
      width > remToPixel(breakpoints.lg) && width <= remToPixel(breakpoints.lg),
    isHigher: width > remToPixel(breakpoints['2xl']),
  };

  return currentBreakpoint;
}
