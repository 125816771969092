import { Route, Routes } from 'react-router-dom';

import { Page404 } from '../components/Page404/Page404';
import { isAuthServerRendering } from '../contexts/auth/authServer';
import { Auth } from '../pages/Auth';
import { Credentials } from '../pages/Credentials';
import { ProtectedRoute } from './ProtectedRoute';

export const Router = () => (
  <Routes>
    <Route
      path="/"
      element={(
        <ProtectedRoute>
          <Credentials />
        </ProtectedRoute>
      )}
    />

    <Route
      path="*"
      element={isAuthServerRendering() ? <Auth /> : <Page404 />}
    />
  </Routes>
);
