import {
  Button, ButtonIcon, Flex, Text,
} from '@memed/epiderme';
import { useCredentials } from '../../../contexts/credentials';
import { useResize } from '../../../hooks/useResize';
import { Keys } from '../../../interfaces/credentials';

interface CardHeaderProps {
  keys: Keys;
  data: string;
}

export const CardHeader = ({ keys, data }: CardHeaderProps) => {
  const context = useCredentials();

  const { isTablet } = useResize();

  const handleRevokeKey = () => {
    const founded = context.state.generatedKeys.find(
      ({ id }: Keys) => keys?.id === id,
    );

    if (founded) {
      context.dispatch({
        type: 'credentials/revoke',
        keyId: keys.id,
      });
    }
  };

  return (
    <Flex
      justifyContent={{ base: 'flex-end', md: 'space-between' }}
      alignItems="center"
      marginLeft="4"
    >
      {!isTablet && (
        <>
          <Text color="neutral.600">{data}</Text>

          <Button
            variant="ghost"
            leftIcon="close"
            size="sm"
            onClick={handleRevokeKey}
          >
            revogar chaves
          </Button>
        </>
      )}

      {isTablet && (
        <ButtonIcon
          icon="close"
          size="sm"
          colorScheme="danger"
          alignSelf="flex-end"
          rounded="full"
          onClick={handleRevokeKey}
        />
      )}
    </Flex>
  );
};
