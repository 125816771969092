import { Box, Flex } from '@memed/epiderme';
import { Sidebar } from '../Sidebar';

export const ProtectedLayout = ({ children }: any) => (
  <Flex
    overflowY="hidden"
    height="100vh"
    bgColor="secondary.50"
    padding={{ base: 0, md: '4' }}
    gap={{ base: 0, md: '8' }}
    overflowX="hidden"
  >
    <Sidebar />

    <Box
      overflowY="auto"
      flex="1"
      bgColor="whiteAlpha.900"
      borderRadius="sm"
      boxShadow="base"
      marginLeft={{ base: '5rem', sm: 0 }}
    >
      {children}
    </Box>
  </Flex>
);
