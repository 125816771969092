import { Box, Flex } from '@memed/epiderme';
import { useState } from 'react';

import { Footer } from './Footer';
import { Header } from './Header';
import { MenuItens } from './MenuItens';

export const Sidebar = () => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

  return (
    <Flex
      position={{ base: 'fixed', sm: 'static' }}
      zIndex="20"
      direction="column"
      height={{ base: '100%', sm: 'auto' }}
      justifyContent="space-between"
      alignItems="center"
      bgColor="whiteAlpha.900"
      borderRadius="sm"
      boxShadow="base"
      transition="width .5s ease-in-out"
      width={{
        base: isCollapsed ? '5rem' : '100%',
        sm: isCollapsed ? '5rem' : '16rem',
      }}
      gap="4"
      paddingY="2"
      paddingX="2"
      boxSizing="border-box"
    >
      <Box width="100%">
        <Header isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />

        <MenuItens setIsCollapsed={setIsCollapsed} />
      </Box>

      <Footer isCollapsed={isCollapsed} />
    </Flex>
  );
};
