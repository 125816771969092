import { useAuth as useOidc } from 'react-oidc-context';
import { ProtectedLayout } from '../components/ProtectedLayout';
import { isAuthServerRendering } from '../contexts/auth/authServer';
import { Auth } from '../pages/Auth';

export const ProtectedRoute = ({ children }: any) => {
  const auth = useOidc();

  if (auth.isAuthenticated && !isAuthServerRendering()) {
    return <ProtectedLayout>{children}</ProtectedLayout>;
  }

  return <Auth />;
};
