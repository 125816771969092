import {
  Box, Card, Flex, Text, Tooltip, TooltipHelper,
} from '@memed/epiderme';
import { useCredentials } from '../../../contexts/credentials';
import { useCopy } from '../../../hooks/useCopy';
import { useResize } from '../../../hooks/useResize';
import { Keys } from '../../../interfaces/credentials';
import { CardHeader } from './CardHeader';

export const CardsList = () => {
  const context = useCredentials();

  const { handleCopy, isCopied } = useCopy();

  const { isTablet } = useResize();

  return (
    <>
      {context.state.generatedKeys.map((keys: Keys) => {
        const dateFormatted = new Date(
          keys.createdAt ? keys.createdAt : new Date(),
        ).toLocaleDateString('pt-br');

        const textData = `Geradas em: ${dateFormatted}`;

        return (
          <Card bgColor="gray.50" key={keys.id} padding="2">
            {!isTablet && (
              <Flex justifyContent="flex-end">
                <TooltipHelper
                  placement="top"
                  labelContent="Clique na chave para copiar"
                />
              </Flex>
            )}

            <CardHeader keys={keys} data={textData} />

            <Box>
              <Flex
                gap="4"
                direction={{ base: 'column', md: 'row' }}
                alignItems={{ base: 'flex-start', md: 'center' }}
                padding="4"
                onClick={() => handleCopy(keys.apiKey)}
              >
                <Text as="caption" fontWeight="bold">
                  API KEY
                </Text>
                <Tooltip
                  labelContent={isCopied ? 'Copiado!' : 'Copiar'}
                  placement="top"
                >
                  <Text
                    fontWeight="normal"
                    color="neutral.600"
                    wordBreak="break-word"
                  >
                    {keys.apiKey}
                  </Text>
                </Tooltip>
              </Flex>

              <Flex
                gap="4"
                direction={{ base: 'column', md: 'row' }}
                alignItems={{ base: 'flex-start', md: 'center' }}
                padding="4"
                bgColor="blue.100"
                rounded="md"
                onClick={() => handleCopy(keys.apiKey)}
              >
                <Text as="caption" fontWeight="bold">
                  SECRET KEY
                </Text>
                <Tooltip
                  labelContent={isCopied ? 'Copiado!' : 'Copiar'}
                  placement="top"
                >
                  <Text
                    fontWeight="normal"
                    color="neutral.600"
                    wordBreak="break-word"
                  >
                    {keys.secretKey}
                  </Text>
                </Tooltip>
              </Flex>

              <Flex
                gap="4"
                direction={{ base: 'column', md: 'row' }}
                alignItems={{ base: 'flex-start', md: 'center' }}
                padding="4"
                onClick={() => handleCopy(keys.apiKey)}
              >
                <Text as="caption" fontWeight="bold">
                  CLIENT KEY
                </Text>
                <Tooltip
                  labelContent={isCopied ? 'Copiado!' : 'Copiar'}
                  placement="top"
                >
                  <Text
                    fontWeight="normal"
                    color="neutral.600"
                    wordBreak="break-word"
                  >
                    {keys.clientKey}
                  </Text>
                </Tooltip>
              </Flex>
            </Box>

            {isTablet && (
              <Flex
                alignItems="center"
                columnGap="1"
                justifyContent="flex-end"
                marginTop="4"
              >
                <TooltipHelper
                  placement="top"
                  labelContent="Clique na chave para copiar"
                />
                <Text fontSize="xs" color="neutral.600" fontStyle="italic">
                  {textData}
                </Text>
              </Flex>
            )}
          </Card>
        );
      })}
    </>
  );
};
