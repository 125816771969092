import { Reducer } from 'react';
import { AsyncActionHandlers } from 'use-reducer-async';

import { api } from '../../services/api';
import { getAuthServerContext } from './authServer';
import {
  Action, LogoutAsyncAction, State, VerifyAsyncAction,
} from './index.d';

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'auth/start': {
      return {
        ...state,
        loading: true,
      };
    }

    case 'auth/setData': {
      return {
        ...state,
        loading: false,
        user: action.response,
      };
    }

    case 'auth/error': {
      return {
        ...state,
        loading: false,
      };
    }

    default: {
      throw new Error('Unhandled action type');
    }
  }
};

const asyncActionHandlers: AsyncActionHandlers<
Reducer<State, Action>,
VerifyAsyncAction | LogoutAsyncAction
> = {
  'auth/verify':
    ({ dispatch }) => async (action) => {
      dispatch({ type: 'auth/start' });

      const {
        url: { loginAction },
      } = getAuthServerContext().login;

      await new Promise<void>((resolve) => {
        ((form) => {
          form.action = loginAction; // eslint-disable-line no-param-reassign
          form.method = 'POST'; // eslint-disable-line no-param-reassign
          form.submit();

          resolve();
        })(action.formRef?.current as HTMLFormElement);
      });
    },
  'auth/logout':
    ({ dispatch, signal }) => async (action) => {
      dispatch({ type: 'auth/start' });

      action.auth.removeUser();

      const data = new URLSearchParams({
        client_id: action.auth.settings.client_id,
        refresh_token: action.auth.user?.refresh_token ?? '',
      });

      try {
        await api({
          url: `${process.env.REACT_APP_AUTH_AUTHORITY_URL}/protocol/openid-connect/logout`,
          method: 'POST',
          headers: {
            Authorization: action.auth.user?.access_token ?? '',
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          },
          data,
        });
      } catch (e) {
        if (!signal.aborted) {
          dispatch({ type: 'auth/error' });
        }
      }
    },
};

export { reducer, asyncActionHandlers };
