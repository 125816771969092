import {
  ReactNode, createContext, useContext, useMemo,
} from 'react';
import { useReducerAsync } from 'use-reducer-async';

import { InitialStateType, State } from './index.d';
import { asyncActionHandlers, reducer } from './reducer';

const INITIAL_STATE = {
  loading: false,
  generatedKeys: [],
};

const initialState = {
  state: INITIAL_STATE,
  dispatch: () => null,
};

const CredentialsContext = createContext<InitialStateType>(initialState);

const CredentialsProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducerAsync(
    reducer,
    INITIAL_STATE,
    asyncActionHandlers,
  );

  const value = useMemo(
    () => ({
      state,
      dispatch,
    }),
    [state, dispatch],
  );

  return (
    <CredentialsContext.Provider value={value}>
      {children}
    </CredentialsContext.Provider>
  );
};

function useCredentials() {
  const context = useContext(CredentialsContext);

  if (!context) {
    throw new Error('useCredentials must be used within a CredentialsProvider');
  }

  return context;
}

export { CredentialsProvider, useCredentials };
