import { Flex } from '@memed/epiderme';
import { ReactNode } from 'react';

interface ContainerProps {
  children: ReactNode;
}

export const Container = ({ children, ...rest }: ContainerProps) => (
  <Flex
    direction="column"
    alignItems="center"
    marginX={{ base: '4', '2xl': 'auto' }}
    marginY="8"
    gap="8"
    maxWidth="1200px"
    {...rest}
  >
    {children}
  </Flex>
);
