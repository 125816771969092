import {
  ReactNode, createContext, useContext, useMemo,
} from 'react';
import { useReducerAsync } from 'use-reducer-async';

import { InitialStateType, State } from './index.d';
import { asyncActionHandlers, reducer } from './reducer';

const INITIAL_STATE = {
  loading: false,
  user: {
    email: '',
    id: '',
    name: '',
    token: '',
  },
};

const initialState = {
  state: INITIAL_STATE,
  dispatch: () => null,
};

const AuthContext = createContext<InitialStateType>(initialState);

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducerAsync(
    reducer,
    INITIAL_STATE,
    asyncActionHandlers,
  );

  const value = useMemo(
    () => ({
      state,
      dispatch,
    }),
    [state, dispatch],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
