import { User } from 'oidc-client-ts';

interface ConfigType {
  authority: string;
  client_id: string;
  redirect_uri: string;
  onSigninCallback: any;
}

const removeStateParametersFromUrl = (_user: User | void): void => {
  window.history.replaceState({}, document.title, window.location.pathname);
};

export const oidcConfig: ConfigType = {
  authority: process.env.REACT_APP_AUTH_AUTHORITY_URL as string,
  client_id: process.env.REACT_APP_AUTH_CLIENT_ID as string,
  redirect_uri: process.env.REACT_APP_AUTH_REDIRECT_URI as string,
  onSigninCallback: removeStateParametersFromUrl,
};
