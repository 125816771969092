interface ComposeType {
  components: Array<React.JSXElementConstructor<React.PropsWithChildren<any>>>;
  children: React.ReactNode;
}

export const Compose = ({ components, children }: ComposeType) => (
  <>
    {components.reduceRight(
      (componentsArray, Component) => (
        <Component>{componentsArray}</Component>
      ),
      children
    )}
  </>
);
