import { ChakraProvider } from '@chakra-ui/react';
import theme from '@memed/epiderme';
import '@memed/epiderme/lib/assets/fonts/fontFace.css';
import '@memed/epiderme/lib/assets/reset.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { AuthProvider } from 'react-oidc-context';
import 'react-toastify/dist/ReactToastify.css';

import App from './App';
import { oidcConfig } from './authConfig';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <ChakraProvider theme={theme}>
    <React.StrictMode>
      <AuthProvider {...oidcConfig}>
        <App />
      </AuthProvider>
    </React.StrictMode>
  </ChakraProvider>,
);
