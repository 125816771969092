import { Divider } from '@chakra-ui/react';
import {
  Avatar, Box, Button, Flex, Text,
} from '@memed/epiderme';
import { useAuth as useOidc } from 'react-oidc-context';

import { useAuth } from '../../contexts/auth';

interface FooterProps {
  isCollapsed: boolean;
}

export const Footer = ({ isCollapsed }: FooterProps) => {
  const context = useAuth();
  const auth = useOidc();

  const customerName = `${auth.user?.profile.given_name} ${auth.user?.profile.family_name}`;
  const customerEmail = auth.user?.profile.email;

  return (
    <Flex
      direction="column"
      width="100%"
      overflowX="hidden"
      bgColor="whiteAlpha.900"
    >
      <Divider />

      <Flex
        paddingTop="4"
        alignItems="center"
        justifyContent={isCollapsed ? 'center' : 'start'}
        gap={isCollapsed ? 0 : '1rem'}
      >
        <Avatar name={customerName} size="sm" />

        {!isCollapsed && (
          <Box flex="1" minWidth="40">
            <Text fontSize="sm">{customerName}</Text>
            <Text fontSize="sm">{customerEmail}</Text>
          </Box>
        )}
      </Flex>

      <Button
        variant="ghost"
        size="md"
        alignSelf={isCollapsed ? 'center' : 'flex-end'}
        onClick={() => {
          context.dispatch({ type: 'auth/logout', auth });
        }}
      >
        sair
      </Button>
    </Flex>
  );
};
