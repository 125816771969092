import { Flex } from '@memed/epiderme';
import { ReactNode } from 'react';

import { Title } from '../Title';

export const Header = ({
  title,
  children,
}: {
  title: string;
  children: ReactNode;
}) => (
  <Flex
    bgColor="whiteAlpha.900"
    position="sticky"
    top="0"
    zIndex="10"
    wrap="wrap"
    gap="8"
    justifyContent={{ base: 'center', md: 'space-between' }}
    alignItems="center"
    padding="4"
    boxShadow="base"
    marginX="auto"
    marginY="0"
  >
    <Title>{title}</Title>

    {children}
  </Flex>
);
