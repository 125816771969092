interface MenuOptionsType {
  id: string;
  label: string;
  icon: string;
  to: string;
}

export const menuOptions: Array<MenuOptionsType> = [
  {
    id: 'credentials',
    label: 'credenciais',
    icon: 'document',
    to: '/',
  },
  {
    id: 'teste',
    label: 'teste',
    icon: 'certificate-shield',
    to: '/teste',
  },
];
