import { useAuth } from 'react-oidc-context';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Loading } from './components/Loading';
import { ContextsProviders } from './contexts';
import { isAuthServerRendering } from './contexts/auth/authServer';
import { Router } from './routes/Router';

const App = () => {
  const { isLoading, isAuthenticated, signinRedirect } = useAuth();

  if (isLoading) {
    return <Loading />;
  }

  if (!isAuthenticated && !isAuthServerRendering()) {
    signinRedirect();

    return <Loading />;
  }

  return (
    <BrowserRouter>
      <ContextsProviders>
        <Router />
        <ToastContainer />
      </ContextsProviders>
    </BrowserRouter>
  );
};

export default App;
