import { AxiosResponse } from 'axios';
import { Reducer } from 'react';
import { toast } from 'react-toastify';
import { AsyncActionHandlers } from 'use-reducer-async';
import {
  Action,
  AddAsyncAction,
  GetAllAsyncAction,
  RevokeAsyncAction,
  State,
} from './index.d';
import { api } from '../../services/api';
import { Keys } from '../../interfaces/credentials';

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'credentials/start': {
      return {
        ...state,
        loading: true,
      };
    }

    case 'credentials/setData': {
      return {
        ...state,
        loading: false,
        generatedKeys: action.response,
      };
    }

    case 'credentials/error': {
      return {
        ...state,
        loading: false,
      };
    }

    default: {
      throw new Error('Unhandled action type');
    }
  }
};

const asyncActionHandlers: AsyncActionHandlers<
Reducer<State, Action>,
GetAllAsyncAction | AddAsyncAction | RevokeAsyncAction
> = {
  'credentials/getAll':
    ({ dispatch, signal }) => async () => {
      dispatch({ type: 'credentials/start' });

      try {
        const response: AxiosResponse = await api.get('/partners/keys', {
          signal,
        });

        if (!signal.aborted) {
          dispatch({
            type: 'credentials/setData',
            response: response.data,
          });
        }
      } catch (e) {
        if (!signal.aborted) {
          dispatch({ type: 'credentials/error' });
        }
      }
    },
  'credentials/add':
    ({ dispatch, getState, signal }) => async () => {
      dispatch({ type: 'credentials/start' });

      try {
        const response: Keys = await toast.promise(api.post('/partners/keys'), {
          pending: 'Criando as credenciais',
          success: 'Credenciais criadas com sucesso',
          error: 'Não foi possível criar as credenciais',
        });

        if (!signal.aborted) {
          dispatch({
            type: 'credentials/setData',
            response: [response, ...getState().generatedKeys],
          });
        }
      } catch (e) {
        if (!signal.aborted) {
          dispatch({ type: 'credentials/error' });
        }
      }
    },
  'credentials/revoke':
    ({ dispatch, getState, signal }) => async (action) => {
      dispatch({ type: 'credentials/start' });

      try {
        await toast.promise(api.delete(`/partners/keys/${action.keyId}`), {
          pending: 'Revogando suas credenciais',
          success: 'Credenciais revogadas com sucesso',
          error: 'Não foi possível revogar as credenciais',
        });

        const currentKeys = getState().generatedKeys.filter(
          (item) => item.id !== action.keyId,
        );

        if (!signal.aborted) {
          dispatch({
            type: 'credentials/setData',
            response: currentKeys,
          });
        }
      } catch (e) {
        if (!signal.aborted) {
          dispatch({ type: 'credentials/error' });
        }
      }
    },
};

export { reducer, asyncActionHandlers };
