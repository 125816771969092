import { Image } from '@chakra-ui/react';
import { ButtonIcon, Flex } from '@memed/epiderme';

import logo from '../../assets/images/logo.png';
import { useResize } from '../../hooks/useResize';

interface HeaderProps {
  isCollapsed: boolean;
  setIsCollapsed: (state: boolean) => void;
}

export const Header = ({ isCollapsed, setIsCollapsed }: HeaderProps) => {
  const icon = isCollapsed ? 'chevron-right' : 'chevron-left';

  const { isTablet, isMobile } = useResize();

  return (
    <Flex position="relative" marginBottom="8" justifyContent="center">
      <Image src={logo} boxSize="3rem" />

      {isMobile && !isCollapsed && (
        <ButtonIcon
          icon="close"
          size="sm"
          variant="ghost"
          position="absolute"
          right="0"
          zIndex="100"
          onClick={() => setIsCollapsed(!isCollapsed)}
        />
      )}

      {!(isMobile && !isCollapsed) && (
        <ButtonIcon
          position="absolute"
          right="-4"
          top="5rem"
          zIndex="100"
          icon={icon}
          rounded="full"
          size="8"
          onClick={() => setIsCollapsed(!isCollapsed)}
        />
      )}
    </Flex>
  );
};
