import { Image } from '@chakra-ui/react';
import {
  Button, Flex, InputEmail, InputText,
} from '@memed/epiderme';
import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useAuth } from '../../contexts/auth';
import logo from '../../assets/images/logo-full.svg';

export const Auth = () => {
  const context = useAuth();
  const formRef = useRef(null);

  const [showValidation, setShowValidation] = useState<boolean>(false);

  const {
    values, dirty, isSubmitting, handleSubmit, handleChange,
  } = useFormik(
    {
      initialValues: {
        username: '',
        password: '',
      },
      onSubmit: () => {
        if (values.username && values.password) {
          context.dispatch({ type: 'auth/verify', formRef });
        }
      },
    },
  );

  useEffect(() => {
    if (dirty) {
      setShowValidation(true);
    }
  }, [isSubmitting, dirty, values]);

  return (
    <Flex
      height="100vh"
      bgColor="secondary.50"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Flex
        alignItems="center"
        direction="column"
        bgColor="whiteAlpha.900"
        padding="8"
        rounded="md"
        justifyContent="center"
        width={{ base: '100%', sm: 'auto' }}
        height={{ base: '100%', sm: 'auto' }}
      >
        <Image
          src={logo}
          alt="logo memed"
          marginBottom="8"
          maxWidth="80%"
          marginX="auto"
        />

        <form
          onSubmit={handleSubmit}
          ref={formRef}
          style={{ width: '100%' }}
          noValidate
        >
          <Flex direction="column" alignItems="center" gap="4">
            <InputEmail
              placeholder="E-mail"
              name="username"
              showValidation={showValidation}
              onChange={handleChange}
              value={values.username}
              isRequired
            />

            <InputText
              placeholder="Senha"
              name="password"
              showValidation={showValidation}
              onChange={handleChange}
              value={values.password}
              isRequired
            />

            <Button
              width="100%"
              size="sm"
              type="submit"
              marginTop="4"
              isDisabled={!values.username || !values.password}
            >
              Entrar
            </Button>
          </Flex>
        </form>
      </Flex>
    </Flex>
  );
};
