import { Flex, Icon, MenuItem } from '@memed/epiderme';
import { useLocation, useNavigate } from 'react-router-dom';

import { useResize } from '../../hooks/useResize';
import { menuOptions } from './menuOptions';

interface MenuItensProps {
  setIsCollapsed: (state: boolean) => void;
}

export const MenuItens = ({ setIsCollapsed }: MenuItensProps) => {
  const { isTablet } = useResize();
  const location = useLocation();
  const navigate = useNavigate();

  const handleClick = (to: string) => {
    navigate(to);

    if (isTablet) {
      setIsCollapsed(true);
    }
  };

  return (
    <Flex
      width="100%"
      marginTop="24"
      justifyContent="space-between"
      direction="column"
      overflowX="hidden"
      gap="4"
      padding="2"
    >
      {menuOptions.map((option) => {
        const active = location.pathname === option.to;

        return (
          <MenuItem
            key={option.id}
            label={option.label}
            isActive={active}
            textTransform="capitalize"
            onClick={() => handleClick(option.to)}
            leftIcon={<Icon icon={option.icon} />}
            padding="3"
          />
        );
      })}
    </Flex>
  );
};
