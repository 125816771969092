import { Spinner } from '@chakra-ui/react';
import { Flex, Text } from '@memed/epiderme';

export const Loading = () => (
  <Flex
    direction="column"
    alignItems="center"
    justifyContent="center"
    gap="4"
    width="100%"
    height="100vh"
  >
    <Spinner />
    <Text>Loading...</Text>
  </Flex>
);
