import { Keys } from '../interfaces/credentials';

type PropType = {
  [key: string]: string | number;
};

const formatter = (REGEX: RegExp, key: string) => key.replace(REGEX, (search: string, match: string) => {
  if (search.includes('_')) {
    return `${match.toUpperCase()}`;
  }

  return `_${search.toLocaleLowerCase()}`;
});

const handleFormat = (
  arrKeys: Array<string>,
  REGEX: RegExp,
  objectUnformatted: PropType,
) => {
  const response = arrKeys.reduce((accumulator: PropType, current: string) => {
    const currentKey = formatter(REGEX, current);

    return { ...accumulator, [currentKey]: objectUnformatted[current] };
  }, {});

  return response;
};

const handleItemObject = (item: PropType, type: string) => {
  const REGEX_CAMEL = /[^a-zA-Z0-9]+(.)/g;
  const REGEX_SNAKE = /[A-Z]/g;

  const objKeys = Object.keys(item);

  if (type === 'camelCase') {
    return handleFormat(objKeys, REGEX_CAMEL, item);
  }

  return handleFormat(objKeys, REGEX_SNAKE, item);
};

export function formatterKeyName(
  type: 'camelCase' | 'snakeCase',
  items: PropType | Array<PropType>,
) {
  if (Array.isArray(items)) {
    return items.map((item: PropType) => handleItemObject(item, type));
  }

  const formatted = handleItemObject(items, type);

  return [formatted];
}
